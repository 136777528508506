import React from "react"
import FeaturesCTA from "./sections/FeaturesCTA"
import Testimonials from "./sections/Testimonials"
import Legal from "./sections/Legal"
import HeroLightSplit from "./sections/HeroLightSplit"
import NotebookImages from "./sections/NotebookImages"
import TextBlock from "./sections/TextBlock"
import Highlight from "./sections/Highlight"
import FeaturesTwoCol from "./sections/FeaturesTwoCol"
import HeroColorBlock from "./sections/HeroColorBlock"
import FeaturesFourGrid from "./sections/FeaturesFourGrid"
import HeroColorBlockImage from "./sections/HeroColorBlockImage"
import HeroTextBlock from "./sections/HeroTextBlock"
import FeaturesImageThreeCol from "./sections/FeaturesImageThreeCol"
import FeaturesThreeCol from "./sections/FeaturesThreeCol"
import HeroImageBlock from "./sections/HeroImageBlock"
import SocialProof from "./sections/SocialProof"
import FeaturesGridBlock from "./sections/FeaturesGridBlock"
import FeaturesImageTwoCol from "./sections/FeaturesImageTwoCol"
import HeroSubNav from "./sections/HeroSubNav"
import FeaturesBoxThreeCol from "./sections/FeaturesBoxThreeCol"
import FeaturesBoxList from "./sections/FeaturesBoxList"
import FeaturesListTwoCol from "./sections/FeaturesListTwoCol"
import PricingBoxTwoCol from "./sections/PricingBoxTwoCol"
import FeaturesIconTwoCol from "./sections/FeaturesIconTwoCol"
import HeroImageLayer from "./sections/HeroImageLayer"
import PricingBoxHighlight from "./sections/PricingBoxHighlight"
import DownloadsBlocks from "./sections/DownloadsBlocks"
import HeroHighlight from "./sections/HeroHighlight"
import InternationalSection from "./sections/InternationalSection"
import HomeHero from "./sections/HomeHero"
import HomeCTA from "./sections/HomeCTA"
import HomeTestimonials from "./sections/HomeTestimonials"
import HomeNotebook from "./sections/HomeNotebook"
import HeroFlexHighlight from "./sections/HeroFlexHighlight"
import TextButtonBanner from "./sections/TextButtonBanner"
import PricingBoxTwoColHighlight from "./sections/PricingBoxTwoColHighlight"
import HeroOverflowHighlight from "./sections/HeroOverflowHighlight"
import FeaturesThreeColCentered from "./sections/FeaturesThreeColCentered"
import HighlightOverflow from "./sections/HighlightOverflow"
import FeaturesBanner from "./sections/FeaturesBanner"
import FeaturesGridBlockIcons from "./sections/FeaturesGridBlockIcons"
import TextBlockImage from "./sections/TextBlockImage"
import HighlightFlexImage from "./sections/HighlightFlexImage"
import FeaturesBulletList from "./sections/FeaturesBulletList"
import FeaturesImageLeft from "./sections/FeaturesImageLeft"
import CtaTextCenter from "./sections/CtaTextCenter"
import HeroTwoRow from "./sections/HeroTwoRow"
import FeaturesImageLeftTwoCol from "./sections/FeaturesImageLeftTwoCol"
import FeaturesTabs from "./sections/FeaturesTabs"
import FeaturesLinksThreeCol from "./sections/FeaturesLinksThreeCol"
import CtaButtonRight from "./sections/CtaButtonRight"
import MobileNotebook from "./sections/MobileNotebook"

class SectionTemplates extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    switch (this.props.__typename) {
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesWithCta":
        return <FeaturesCTA {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_TestimonialsSlideshow":
        return <Testimonials {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_Legal":
        return <Legal {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_HeroLightSplit":
        return <HeroLightSplit {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_NotebookImages":
        return <NotebookImages {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_TextBlock":
        return <TextBlock {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_Highlight":
        return <Highlight {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesTwoCol":
        return <FeaturesTwoCol {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_HeroColorBlock":
        return <HeroColorBlock {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesFourGrid":
        return <FeaturesFourGrid {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_HeroColorBlockImage":
        return <HeroColorBlockImage {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_HeroTextBlock":
        return <HeroTextBlock {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesImageThreeCol":
        return <FeaturesImageThreeCol {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesThreeCol":
        return <FeaturesThreeCol {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_HeroImageBlock":
        return <HeroImageBlock {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_SocialProof":
        return <SocialProof {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesGridBlock":
        return <FeaturesGridBlock {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesImageTwoCol":
        return <FeaturesImageTwoCol {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_HeroSubNav":
        return <HeroSubNav {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesBoxThreeCol":
        return <FeaturesBoxThreeCol {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesBoxList":
        return <FeaturesBoxList {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesListTwoCol":
        return <FeaturesListTwoCol {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_PricingBoxTwoCol":
        return <PricingBoxTwoCol {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesIconTwoCol":
        return <FeaturesIconTwoCol {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesLinksThreeCol":
        return <FeaturesLinksThreeCol {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_HeroImageLayer":
        return <HeroImageLayer {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_PricingBoxHighlight":
        return <PricingBoxHighlight {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_DownloadsBlocks":
        return <DownloadsBlocks {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_HeroHighlight":
        return <HeroHighlight {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_InternationalSection":
        return <InternationalSection {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_HomeHero":
        return <HomeHero {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_HomeCta":
        return <HomeCTA {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_HomeTestimonials":
        return <HomeTestimonials {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_HomeNotebook":
        return <HomeNotebook {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_HeroFlexHighlight":
        return <HeroFlexHighlight {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_TextButtonBanner":
        return <TextButtonBanner {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_PricingBoxTwoColHighlight":
        return <PricingBoxTwoColHighlight {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_HeroOverflowHighlight":
        return <HeroOverflowHighlight {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesThreeColCentered":
        return <FeaturesThreeColCentered {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_HighlightOverflow":
        return <HighlightOverflow {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesBanner":
        return <FeaturesBanner {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesGridBlockIcons":
        return <FeaturesGridBlockIcons {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_TextBlockImage":
        return <TextBlockImage {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_HighlightFlexImage":
        return <HighlightFlexImage {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesBulletList":
        return <FeaturesBulletList {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesImageLeft":
        return <FeaturesImageLeft {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_CtaTextCenter":
        return <CtaTextCenter {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_HeroTwoRow":
        return <HeroTwoRow {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesImageLeftTwoCol":
        return <FeaturesImageLeftTwoCol {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_FeaturesTabs":
        return <FeaturesTabs {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_CtaButtonRight":
        return <CtaButtonRight {...this.props} />
        break
      case "WPGraphQL_Page_Acftemplates_Templates_NotebookMobileImages":
        return <MobileNotebook {...this.props} />
        break
    }
  }
}

export default SectionTemplates
