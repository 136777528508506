import React from "react"
import { useMediaQuery } from "react-responsive"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import Img from "gatsby-image"

import styles from "../../styles/notebook.module.scss"

import notebookBillingCreative from "../../images/notebook-billing-creative.png"
import notebookRecordingsCreative from "../../images/notebook-recordings-creative.png"
import notebookUsersCreative from "../../images/notebook-users-creative.png"
import noteboookTrackingCreative from "../../images/noteboook-tracking-creative.png"

const NotebookImages = props => {
  const isMobile = useMediaQuery({ maxWidth: 1000 })

  const wpTabColumnStyles = {
    maxWidth: props.tabColumnWidth ? `${props.tabColumnWidth}px` : null,
    marginRight: props.tabsMargin ? `${props.tabsMargin}px` : null,
  }

  const wpImageStyles = {
    maxWidth: props.imageSize.width ? `${props.imageSize.width}px` : null,
    maxHeight: props.imageSize.height ? `${props.imageSize.height}px` : null,
  }

  const wpContainerStyles = {
    paddingTop:
      props.padding.top && !isMobile ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom && !isMobile ? `${props.padding.bottom}px` : null,
  }

  return (
    <div
      className={styles.section}
      style={{ backgroundColor: props.background ? props.background : null }}
    >
      <div className={styles.container} style={wpContainerStyles}>
        <div
          className={styles.header}
          style={{
            maxWidth: props.titleWidth ? `${props.titleWidth}px` : null,
          }}
        >
          <div className={styles.eyebrow}>{props.eyebrow}</div>
          <h2 className={styles.copy}>{props.title}</h2>
        </div>

        <Tabs className={styles.notebookContainer}>
          <TabList
            className={styles.tabContainer}
            style={!isMobile ? wpTabColumnStyles : null}
          >
            {props.tabs.map((tab, index) => (
              <Tab key={index} className={styles.tab}>
                <div className={styles.tabTitle}>{tab.title}</div>
                <p className={styles.tabText}>{tab.subtext}</p>
              </Tab>
            ))}
          </TabList>

          <TabPanel>
            <img
              src={notebookUsersCreative}
              alt="notebook users creative"
              style={wpImageStyles}
            />
          </TabPanel>

          <TabPanel>
            <img
              src={noteboookTrackingCreative}
              alt="notebook tracking creative"
              style={wpImageStyles}
            />
          </TabPanel>

          <TabPanel>
            <img
              src={notebookRecordingsCreative}
              alt="notebook recordings creative"
              style={wpImageStyles}
            />
          </TabPanel>

          <TabPanel>
            <img
              src={notebookBillingCreative}
              alt="notebook billing creative"
              style={wpImageStyles}
            />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

export default NotebookImages
