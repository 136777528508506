import React from "react"
import { useMediaQuery } from "react-responsive"
import Img from "gatsby-image"
import styles from "../../styles/hero-color-block-image.module.scss"

const HeroColorBlockImage = props => {
  const isMobile = useMediaQuery({ maxWidth: 1100 })

  const wordpressStyles = {
    backgroundColor: props.bgColor,
    paddingTop: props.padding.top ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom && !isMobile ? `${props.padding.bottom}px` : null,
  }

  return (
    <div
      className={`${styles.hero} ${props.class ? styles[props.class] : ""}`}
      style={wordpressStyles}
    >
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <h1 className={styles.title}>{props.title}</h1>
          <h2 className={styles.copy}>{props.copy}</h2>
          <div
            className={styles.subtext}
            dangerouslySetInnerHTML={{ __html: props.subtext }}
          />
        </div>
        <div className={styles.imageContainer}>
          <Img
            fluid={props.image.imageFile.childImageSharp.fluid}
            alt={props.image.altText}
          />
        </div>
      </div>
    </div>
  )
}

export default HeroColorBlockImage
