import React from "react"
import { useMediaQuery } from "react-responsive"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styles from "../../styles/highlight.module.scss"
import appleStoreBadege from "../../images/apple-app-store-badge.svg"

const Highlight = props => {
  const isMobile = useMediaQuery({ maxWidth: 1100 })

  const wpWidth = props.customizeImage.width
  const wpHeight = props.customizeImage.height
  const wpX = props.customizeImage.translatex
  const wpY = props.customizeImage.translatey
  const wpAlign = props.customizeImage.align

  const wpImageStyles = {
    maxWidth: wpWidth ? `${wpWidth}px` : null,
    transform:
      !isMobile &&
      `translateX(${wpX ? wpX : "0"}px) translateY(${wpY ? wpY : "0"}px)`,
  }

  const wpPaddingStyles = {
    paddingTop: props.padding.top !== null ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom !== null ? `${props.padding.bottom}px` : null,
    borderTop: props.border.top ? "1px solid #E6E8EB" : null,
    borderBottom: props.border.bottom ? "1px solid #E6E8EB" : null,
  }

  const isRight = props.direction === "right"

  const pluginOptions = {
    wordPressUrl: `${process.env.GATSBY_WP_DOMAIN}/`,
    uploadsUrl: `${process.env.GATSBY_WP_DOMAIN}/wp-content/uploads/`,
  }

  return (
    <div
      className={`${styles.section} ${props.class ? styles[props.class] : ""}`}
      style={{
        backgroundColor: `${props.background ? props.background : null}`,
      }}
    >
      <div
        className={`${styles.container} ${isRight ? styles.flip : null}`}
        style={!isMobile ? wpPaddingStyles : null}
      >
        <div
          className={`${styles.textContainer} ${isRight ? styles.flip : null}`}
        >
          <div className={styles.eyebrow}>{props.eyebrow}</div>
          <h2 className={styles.title}>{props.title}</h2>
          <div
            className={styles.subtext}
            dangerouslySetInnerHTML={{ __html: props.subtext }}
          />
          {props.class === "omMobileHighlight" && (
            <a
              href="https://apps.apple.com/us/app/vast-conference/id1457035935"
              target="_blank"
            >
              <img
                className={styles.appleBadge}
                src={appleStoreBadege}
                alt="apple store badge"
              />
            </a>
          )}
          {props.button && (
            <Link to={props.button.url} className={styles.button}>
              {props.button.title}
            </Link>
          )}
        </div>
        <div
          className={`${styles.imageContainer} ${
            wpAlign === "left" && !isMobile ? styles.alignLeft : ""
          } ${wpAlign === "right" && !isMobile ? styles.alignRight : ""} ${
            props.class ? styles[props.class] : ""
          }`}
        >
          <div className={styles.gatsbyWrap} style={wpImageStyles}>
            <Img
              fluid={props.image.imageFile.childImageSharp.fluid}
              alt={props.image.altText}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Highlight
