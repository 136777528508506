import React from "react"
import styles from "../../styles/tos-privacy.module.scss"

const Legal = props => {
  return (
    <>
      <div className={styles.titleContainer}>
        <h1>{props.title}</h1>
      </div>
      <div className={styles.contentContainer}>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: props.text }}
        />
      </div>
    </>
  )
}

export default Legal
