import React from "react"
import { Link } from "gatsby"
import { useMediaQuery } from "react-responsive"
import Img from "gatsby-image"
import camelcase from "lodash.camelcase"

import styles from "../../styles/home-hero.module.scss"

const HomeHero = props => {
  return (
    <div className={styles.hero}>
      <video
        className={styles.bannerVideo}
        autoPlay
        muted
        loop
        controlsList="nodownload"
        disablePictureInPicture
      >
        <source
          src={`/videos/studiobit-hero.mp4`}
          type="video/mp4"
        />
      </video>
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <h1 className={styles.copy}>{props.copy}</h1>
          <p className={styles.subtext}>{props.subtext}</p>
          <div className={styles.buttonContainer}>
            <Link className={styles.left} to={props.buttons.mainButton.url}>
              {props.buttons.mainButton.title.toUpperCase()}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeHero
