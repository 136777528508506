import React from "react"
import { useMediaQuery } from "react-responsive"
import { Link } from "gatsby"
import Img from "gatsby-image"

import styles from "../../styles/international-section.module.scss"
import checkCircle from "../../images/check-circle-icon.svg"

const InternationalSection = props => {
  const isMobile = useMediaQuery({ maxWidth: 1100 })

  const wordpressStyles = {
    paddingTop: props.paddingTop ? `${props.paddingTop}px` : null,
    paddingBottom:
      props.paddingBottom && !isMobile ? `${props.paddingBottom}px` : null,
  }

  const list = props.countryList
  const more = props.moreCountries

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.listsContainer}>
          <div className={styles.list}>
            {/* header */}
            <div className={styles.header}>
              <div className={styles.country}>{list.colOneHeading}</div>
              <div className={styles.localAccess}>{list.colTwoHeading}</div>
              <div className={styles.tollFree}>{list.colThreeHeading}</div>
            </div>
            {list.row.map((row, index) => (
              <div key={index} className={styles.row}>
                <div className={styles.country}>
                  <span
                    className={`${styles.flag} ${
                      row.included ? styles.included : ""
                    }`}
                  >
                    <Img
                      fixed={row.flag.imageFile.childImageSharp.fixed}
                      alt={row.flag.altText}
                    />
                  </span>
                  {row.country}
                </div>
                <div className={styles.localAccess}>{row.localAccess}</div>
                <div className={styles.tollFree}>{row.tollFree}</div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.featuresContainer}>
          {props.features.map((feature, index) => (
            <div key={index} className={styles.feature}>
              <div className={styles.titleContainer}>
                <span className={styles.icon}>
                  <img src={checkCircle} alt={feature.icon.altText} />
                </span>
                <h3 className={styles.title}>{feature.title}</h3>
              </div>
              <div
                className={styles.subtext}
                dangerouslySetInnerHTML={{ __html: feature.subtext }}
              />
            </div>
          ))}
          <div className={styles.moreContainer}>
            <h3 className={styles.title}>{more.title}</h3>
            <p className={styles.subtext}>{more.subtext}</p>
            <Link to={more.button.url} className={styles.button}>
              {more.button.title}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InternationalSection
