import React from "react"
import { useMediaQuery } from "react-responsive"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styles from "../../styles/hero-flex-highlight.module.scss"

const HeroFlexHighlight = props => {
  const isMobile = useMediaQuery({ maxWidth: 1040 })

  return (
    <div
      className={`${styles.section} ${props.class ? styles[props.class] : ""}`}
    >
      <div className={`${styles.container}`}>
        <div className={`${styles.textContainer}`}>
          <h1 className={styles.title}>{props.title}</h1>
          <div
            className={styles.subtext}
            dangerouslySetInnerHTML={{ __html: props.subtext }}
          />

          <div className={styles.buttonContainer}>
            {props.button && (
              <Link to={props.button.url} className={styles.button}>
                {props.button.title}
              </Link>
            )}
            {props.secondaryButton && (
              <Link
                to={props.secondaryButton.url}
                className={styles.secondaryButton}
              >
                {props.secondaryButton.title}
              </Link>
            )}
          </div>
        </div>

        <div className={styles.imageContainer}>
          <Img
            fluid={props.image.imageFile.childImageSharp.fluid}
            alt={props.image.altText}
          />
        </div>
      </div>
    </div>
  )
}

export default HeroFlexHighlight
