import React from "react"
import { Link } from "gatsby"
import { useMediaQuery } from "react-responsive"
import styles from "../../styles/features-banner.module.scss"
import checkIcon from "../../images/check-purple-icon.svg"

const FeaturesBanner = props => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const wordpressStyles = {
    backgroundColor: props.bgColor,
    paddingTop:
      props.padding.top && !isMobile ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom && !isMobile ? `${props.padding.bottom}px` : null,
  }

  return (
    <div
      className={`${styles.section} ${props.class ? styles[props.class] : ""}`}
      style={wordpressStyles}
    >
      <div className={styles.container}>
        <div className={styles.featuresContainer}>
          {props.features.map((feature, index) => (
            <div key={index} className={styles.feature}>
              <div className={styles.titleContainer}>
                <span className={styles.icon}>
                  <img src={checkIcon} alt="check icon" />
                </span>
                <span className={styles.title}>{feature.title}</span>
              </div>
              <div
                className={styles.subtext}
                dangerouslySetInnerHTML={{ __html: feature.subtext }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FeaturesBanner
