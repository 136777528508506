import React from "react"
import { Link } from "gatsby"
import { useMediaQuery } from "react-responsive"
import styles from "../../styles/cta-text-center.module.scss"

const CtaTextCenter = props => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const wordpressStyles = {
    backgroundColor: props.bgColor,
    paddingTop:
      props.padding.top && !isMobile ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom && !isMobile ? `${props.padding.bottom}px` : null,
  }

  return (
    <div
      className={`${styles.ctaSection} ${
        props.class ? styles[props.class] : ""
      }`}
      style={wordpressStyles}
    >
      <div className={styles.container}>
        <div className={styles.copy}>
          <h2>{props.copy}</h2>
        </div>
        <div className={styles.buttonContainer}>
          <Link to={props.button.url}>{props.button.title}</Link>
        </div>
      </div>
    </div>
  )
}

export default CtaTextCenter
