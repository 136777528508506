import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import styles from "../../styles/testimonials.module.scss"

class Testimonials extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      dotsClass: "reactz-dots",
    }

    return (
      <Slider className={styles.section} {...settings}>
        {this.props.testimonials.map((testimonial, index) => (
          <div key={index} className={styles.box}>
            <div className={styles.testimonialContainer}>
              {testimonial.image && (
                <div className={styles.profile}>
                  <div className={styles.imageContainer}>
                    <img
                      src={testimonial.image.sourceUrl}
                      alt="customer profile"
                    />
                  </div>
                </div>
              )}
              <div className={styles.quote}>
                &#8220;{testimonial.quote}&#8221;
              </div>
              <div className={styles.quoteDetails}>
                {testimonial.name} <span>/</span> {testimonial.company}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    )
  }
}

export default Testimonials
