import React from "react"
import { Link } from "gatsby"
import { useMediaQuery } from "react-responsive"
import Img from "gatsby-image"

import styles from "../../styles/text-block.module.scss"

const TextBlock = props => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const wordpressStyles = {
    backgroundColor: props.bgColor,
    paddingTop:
      props.padding.top && !isMobile ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom && !isMobile ? `${props.padding.bottom}px` : null,
  }

  return (
    <div
      className={`${styles.section} ${props.class ? styles[props.class] : ""}`}
      style={wordpressStyles}
    >
      <div className={styles.container}>
        {props.image && (
          <div className={styles.imageContainer}>
            <Img
              fluid={props.image.imageFile.childImageSharp.fluid}
              alt={props.image.altText}
            />
          </div>
        )}
        <h2 className={styles.heading}>{props.title}</h2>
        <div
          className={styles.subtext}
          dangerouslySetInnerHTML={{ __html: props.subtext }}
        />
        {props.button && (
          <Link to={props.button.url} className={styles.button}>
            {props.button.title}
          </Link>
        )}
      </div>
    </div>
  )
}

export default TextBlock
