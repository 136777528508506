import React, { useState, useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import Img from "gatsby-image"
import styles from "../../styles/features-tabs.module.scss"
import testSlide from "../../images/delete/testSlide.png"

const FeaturesTabs = props => {
  const isMobile = useMediaQuery({ maxWidth: 868 })
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.subtext}>{props.subtext}</div>

        {!isMobile && (
          <Tabs
            className={styles.tabsContainer}
            selectedIndex={tabIndex}
            onSelect={index => setTabIndex(index)}
          >
            <TabList className={styles.tabList}>
              {props.features.map((tabs, index) => (
                <Tab
                  key={index}
                  className={`${styles.tab} ${
                    tabIndex === index ? styles.active : ""
                  }`}
                >
                  {tabs.tab}
                </Tab>
              ))}
            </TabList>

            {props.features.map((tabpanels, index) => (
              <TabPanel key={index} className={styles.tabPanel}>
                <div className={styles.image}>
                  <Img
                    fluid={tabpanels.image.imageFile.childImageSharp.fluid}
                    alt={tabpanels.image.altText}
                  />
                </div>
                <div className={styles.content}>
                  <h3>{tabpanels.title}</h3>
                  <div
                    className={styles.contentSubtext}
                    dangerouslySetInnerHTML={{ __html: tabpanels.subtext }}
                  />
                </div>
              </TabPanel>
            ))}
          </Tabs>
        )}

        {isMobile &&
          props.features.map((tabpanels, index) => (
            <div key={index} className={styles.tabPanel}>
              <div className={styles.image}>
                <Img
                  fluid={tabpanels.image.imageFile.childImageSharp.fluid}
                  alt={tabpanels.image.altText}
                />
              </div>
              <div className={styles.content}>
                <h3>{tabpanels.title}</h3>
                <div
                  className={styles.contentSubtext}
                  dangerouslySetInnerHTML={{ __html: tabpanels.subtext }}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default FeaturesTabs
