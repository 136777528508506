import React from "react"
import camelcase from "lodash.camelcase"
import styles from "../../styles/features-four-grid.module.scss"

const FeaturesFourGrid = props => {
  const wpBorderTop = props.border.top
  const wpBorderBottom = props.border.bottom
  const wpPaddingTop = props.padding.top
  const wpPaddingBottom = props.padding.botton

  const wpContainerStyles = {
    borderTop: wpBorderTop ? "1px solid #E6E8EB" : null,
    borderBottom: wpBorderBottom ? "1px solid #E6E8EB" : null,
    paddingTop: wpPaddingTop ? `${wpPaddingTop}px` : null,
    paddingBottom: wpPaddingBottom ? `${wpPaddingBottom}px` : null,
  }

  return (
    <div
      className={`${styles.section} ${props.class ? styles[props.class] : ""}`}
    >
      <div className={styles.container} style={wpContainerStyles}>
        <div className={styles.textContainer}>
          <div className={styles.eyebrow}>{props.eyebrow}</div>
          <div className={styles.title}>{props.title}</div>
          {props.subtext && (
            <div className={styles.subtext}>{props.subtext}</div>
          )}
        </div>
        <div className={styles.featuresContainer}>
          {props.features.map((feature, index) => (
            <div key={index} className={styles.feature}>
              <div className={styles.iconContainer}>
                <img
                  src={`/images/acf-icons/${feature.icon.slug}.svg`}
                  alt={feature.icon.altText}
                />
              </div>
              <div className={styles.featureTextContainer}>
                <div className={styles.featureTitle}>{feature.title}</div>
                <div className={styles.featureSubtext} dangerouslySetInnerHTML={{ __html: feature.subtext }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FeaturesFourGrid
