import React from "react"
import styles from "../../styles/features-cta.module.scss"

class FeaturesCTA extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.featureContainer}>
          <div className={styles.feature}>
            <div className={styles.icon}>
              <div></div>
            </div>
            <div>
              <h4>Billed with Credit Card</h4>
              <p>
                Accounts are billed by credit card with payment receipts emailed
                and call details reported in your online portal.
              </p>
              <a href="#">Get Started</a>
            </div>
          </div>
        </div>

        <div className={styles.featureContainer}>
          <div className={styles.feature}>
            <div className={styles.icon}>
              <div></div>
            </div>
            <div>
              <h4>Instant Trial Activation</h4>
              <p>
                Your trial is ready to use instantly with our full suite of
                services. Host calls & meetings free for 14 days.
              </p>
              <a href="#">Get Started</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FeaturesCTA
