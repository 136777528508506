import React from "react"
import { useMediaQuery } from "react-responsive"
import { Link } from "gatsby"
import Img from "gatsby-image"

import styles from "../../styles/hero-image-layer.module.scss"

const HeroImageLayer = props => {
  const isMobile = useMediaQuery({ maxWidth: 1100 })

  const wordpressStyles = {
    paddingTop: props.paddingTop ? `${props.paddingTop}px` : null,
    paddingBottom:
      props.paddingBottom && !isMobile ? `${props.paddingBottom}px` : null,
  }

  return (
    <div className={`${styles.hero} ${props.class ? styles[props.class] : ""}`}>
      <div className={styles.container}>
        <div className={styles.textContainer}>
          {props.title && <h1 className={styles.title}>{props.title}</h1>}
          {props.title ? (
            <h2 className={styles.copy}>{props.copy}</h2>
          ) : (
            <h1 className={styles.copy}>{props.copy}</h1>
          )}

          <div
            className={styles.subtext}
            dangerouslySetInnerHTML={{ __html: props.subtext }}
          />
          {props.button && (
            <Link to={props.button.url} className={styles.ctaButton}>
              {props.button.title}
            </Link>
          )}
        </div>
        <div className={styles.imageContainer}>
          <Img
            fluid={props.image.imageFile.childImageSharp.fluid}
            alt={props.image.altText}
          />
        </div>
      </div>
    </div>
  )
}

export default HeroImageLayer
