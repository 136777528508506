import React from "react"
import { useMediaQuery } from "react-responsive"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styles from "../../styles/highlight-overflow.module.scss"

const HighlightOverflow = props => {
  const isMobile = useMediaQuery({ maxWidth: 1100 })

  const wpPaddingStyles = {
    paddingTop: props.padding.top !== null ? `${props.padding.top}px` : null,
  }

  const isRight = props.direction === "right"

  return (
    <div
      className={`${styles.section} ${props.class ? styles[props.class] : ""}`}
      style={{
        backgroundColor: `${props.background ? props.background : null}`,
      }}
    >
      <div
        className={`${styles.container} ${isRight ? styles.flip : null}`}
        style={!isMobile ? wpPaddingStyles : null}
      >
        <div
          className={`${styles.textContainer} ${isRight ? styles.flip : null}`}
        >
          {props.eyebrow && (
            <div className={styles.eyebrow}>{props.eyebrow}</div>
          )}
          <h2 className={styles.title}>{props.title}</h2>
          <div
            className={styles.subtext}
            dangerouslySetInnerHTML={{ __html: props.subtext }}
          />
          {props.button && (
            <Link to={props.button.url} className={styles.button}>
              {props.button.title}
            </Link>
          )}
        </div>
        <div
          className={`${styles.imageContainer} ${isRight ? styles.flip : null}`}
          // style={wpImageStyles}
        >
          <div className={styles.gatsbyWrap}>
            {!isMobile && (
              <Img
                fixed={props.image.imageFile.childImageSharp.fixed}
                alt={props.image.altText}
              />
            )}
            {isMobile && (
              <Img
                fluid={props.image.imageFile.childImageSharp.fluid}
                alt={props.image.altText}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HighlightOverflow
