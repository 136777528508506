import React from "react"
import { Link } from "gatsby"
import camelcase from "lodash.camelcase"
import styles from "../../styles/features-grid-block-icons.module.scss"

const FeaturesGridBlockIcons = props => {
  const wpPaddingTop = props.padding.top
  const wpPaddingBottom = props.padding.botton
  const wpBgColor = props.bgColor

  const wpSectionStyles = {
    backgroundColor: wpBgColor ? wpBgColor : null,
  }

  const wpContainerStyles = {
    paddingTop: wpPaddingTop ? `${wpPaddingTop}px` : null,
    paddingBottom: wpPaddingBottom ? `${wpPaddingBottom}px` : null,
  }

  return (
    <div className={styles.section} style={wpSectionStyles}>
      <div className={styles.container} style={wpContainerStyles}>
        <div className={styles.textContainer}>
          {props.eyebrow && (
            <div className={styles.eyebrow}>{props.eyebrow}</div>
          )}
          <div className={styles.title}>{props.title}</div>
          {props.subtext && (
            <div className={styles.subtext}>{props.subtext}</div>
          )}
        </div>
        <div className={styles.featuresContainer}>
          {props.features.map((feature, index) => (
            <div key={index} className={`${styles.feature}`}>
              <div className={styles.titleContainer}>
                <span className={styles.icon}>
                  <img
                    src={`/images/acf-icons/${feature.icon.slug}.svg`}
                    alt={feature.icon.altText}
                  />
                </span>
                <span className={styles.featureTitle}>{feature.title}</span>
              </div>
              <div
                className={styles.featureSubtext}
                dangerouslySetInnerHTML={{ __html: feature.subtext }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FeaturesGridBlockIcons
