import React from "react"
import { Link } from "gatsby"

import styles from "../../styles/pricing-box-highlight.module.scss"

const PricingBoxHighlight = props => {
  return (
    <div className={styles.container}>
      <div className={styles.pricingColumn}>
        <div className={styles.pricingBox}>
          <div className={styles.textContainer}>
            <div className={styles.planType}>
              {props.pricingBox.headingText}
            </div>
            <div className={styles.price}>{props.pricingBox.price}</div>
            <div className={styles.priceSub}>{props.pricingBox.priceSub}</div>

            <div
              className={styles.subtext}
              dangerouslySetInnerHTML={{ __html: props.pricingBox.subtext }}
            />
          </div>

          <Link className={styles.planButton} to={props.pricingBox.button.url}>
            {props.pricingBox.button.title}
          </Link>
        </div>
      </div>

      <div className={styles.textColumn}>
        <div className={styles.textContainer}>
          <div className={styles.title}>{props.textColumn.title}</div>
          <div className={styles.subtext}>{props.textColumn.subtext}</div>
        </div>
        <Link className={styles.button} to={props.textColumn.button.url}>
          {props.textColumn.button.title}
        </Link>
      </div>
    </div>
  )
}

export default PricingBoxHighlight
