import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionTemplates from "./SectionTemplates"

// graphql query
export const query = graphql`
  query GET_PAGE($pageId: Int!) {
    wpgraphql {
      pageBy(pageId: $pageId) {
        slug
        acfTemplates {
          templates {
            # allTemplates fragments in fragments.js
            ...allTemplates
          }
        }
        seo {
          metaDesc
          title
          opengraphTitle
          opengraphDescription
          twitterDescription
          twitterTitle
        }
      }
    }
  }
`

// array of hero templates (typenames) that require alternate page navigation styles
const heroSections = [
  "WPGraphQL_Page_Acftemplates_Templates_HeroColorBlock",
  "WPGraphQL_Page_Acftemplates_Templates_HeroImageBlock",
  "WPGraphQL_Page_Acftemplates_Templates_HomeHero",
]

const Page = ({ data }) => {
  const slug = data.wpgraphql.pageBy.slug
  const sectionTemplates = data.wpgraphql.pageBy.acfTemplates.templates
  const seo = data.wpgraphql.pageBy.seo

  // boolean based on intersection between sectionTemplates and heroSections
  const isNavAlternate = sectionTemplates.some(section =>
    heroSections.includes(section.__typename)
  )

  return (
    <Layout colorBlock={isNavAlternate}>
      <SEO {...seo} />
      {sectionTemplates.map((template, index) => (
        <div key={index}>
          <SectionTemplates {...template} />
        </div>
      ))}
    </Layout>
  )
}

export default Page
