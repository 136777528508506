import React from "react"
import { useMediaQuery } from "react-responsive"
import camelcase from "lodash.camelcase"
import styles from "../../styles/features-threecol.module.scss"

const FeaturesThreeCol = props => {
  const isMobile = useMediaQuery({ maxWidth: 1100 })

  const wordpressStyles = {
    backgroundColor: props.bgColor,
    paddingTop: props.padding.top ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom && !isMobile ? `${props.padding.bottom}px` : null,
  }

  return (
    <div
      className={`${styles.section} ${props.class ? styles[props.class] : ""}`}
      style={wordpressStyles}
    >
      <div className={styles.container}>
        {props.title || props.subtext ? (
          <div className={styles.textContainer}>
            <h2 className={styles.title}>{props.title}</h2>
            {props.subtext && <p className={styles.subtext}>{props.subtext}</p>}
          </div>
        ) : null}
        <div className={styles.featuresContainer}>
          {props.features.map((feature, index) => (
            <div key={index} className={styles.feature}>
              <div className={styles.titleContainer}>
                <span className={styles.icon}>
                  <img
                    src={`/images/acf-icons/${feature.icon.slug}.svg`}
                    alt={feature.icon.altText}
                  />
                </span>
                <span className={styles.title}>{feature.title}</span>
              </div>
              <div
                className={styles.subtext}
                dangerouslySetInnerHTML={{ __html: feature.subtext }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FeaturesThreeCol
