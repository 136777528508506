import React from "react"
import { useMediaQuery } from "react-responsive"
import { Link } from "gatsby"
import Img from "gatsby-image"

import styles from "../../styles/hero-tworow.module.scss"

const HeroTwoRow = props => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const topRowStyles = {
    paddingTop: props.paddingTop && !isMobile ? `${props.paddingTop}px` : null,
    paddingBottom:
      props.paddingBottom && !isMobile ? `${props.paddingBottom}px` : null,
  }

  const highlightRowStyles = {
    paddingTop:
      props.highlight.paddingTop && !isMobile
        ? `${props.highlight.paddingTop}px`
        : null,
    paddingBottom:
      props.highlight.paddingBottom && !isMobile
        ? `${props.highlight.paddingBottom}px`
        : null,
  }

  return (
    <div className={styles.hero}>
      <div
        className={`${styles.titleRow} ${
          props.class ? styles[props.class] : ""
        }`}
        style={topRowStyles}
      >
        <div className={styles.container}>
          <div className={styles.textContainer}>
            {props.title && <h1 className={styles.title}>{props.title}</h1>}
            {props.title ? (
              <h2 className={styles.copy}>{props.copy}</h2>
            ) : (
              <h1 className={styles.copy}>{props.copy}</h1>
            )}
            {!isMobile && (
              <div
                className={styles.subtext}
                dangerouslySetInnerHTML={{ __html: props.subtext }}
              />
            )}
            {isMobile && (
              <div className={styles.subtext}>
                {/* remove p tags from wisiwyg data */}
                {props.subtext.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "")}
              </div>
            )}
            {props.button && (
              <Link to={props.button.url} className={styles.ctaButton}>
                {props.button.title}
              </Link>
            )}
          </div>
        </div>
      </div>

      <div
        className={`${styles.highlightRow} ${
          props.class ? styles[props.class] : ""
        }`}
        style={{
          backgroundColor: `${props.background ? props.background : null}`,
        }}
      >
        <div
          className={styles.highlightContainer}
          style={highlightRowStyles}
        >
          <div className={styles.textContainer}>
            {props.highlight.eyebrow && (
              <div className={styles.eyebrow}>{props.highlight.eyebrow}</div>
            )}
            <h2 className={styles.highlightTitle}>{props.highlight.title}</h2>
            <div
              className={styles.highlightSubtext}
              dangerouslySetInnerHTML={{ __html: props.highlight.subtext }}
            />
            {props.highlight.button && (
              <Link to={props.highlight.button.url} className={styles.button}>
                {props.button.highlight.title}
              </Link>
            )}
          </div>
          <div
            className={styles.imageContainer}
          >
            <div className={styles.gatsbyWrap}>
              {!isMobile && (
                <Img
                  fixed={props.highlight.image.imageFile.childImageSharp.fixed}
                  alt={props.highlight.image.altText}
                />
              )}
              {isMobile && (
                <Img
                  fluid={props.highlight.image.imageFile.childImageSharp.fluid}
                  alt={props.highlight.image.altText}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroTwoRow
