import React, { useState, useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import Img from "gatsby-image"

import styles from "../../styles/home-notebook.module.scss"

const HomeNotebook = props => {
  const isMobile = useMediaQuery({ maxWidth: 962 })

  const [isSlidesActive, setSlidesActive] = useState({
    slideA: true,
    slideB: false,
    slideC: false,
    slideD: false,
  })

  function handleSlideState(e) {
    clearState()
    setSlidesActive({
      [e.target.dataset.tab]: true,
    })
  }

  function clearState() {
    setSlidesActive({
      slideA: false,
      slideB: false,
      slideC: false,
      slideD: false,
    })
  }

  return (
    <div className={styles.section}>
      <Tabs className={styles.container}>
        <div className={styles.contentContainer}>
          {props.slides.map((slide, index) => (
            <TabPanel key={index} className={styles.panel}>
              <Img
                fluid={slide.content.imageFile.childImageSharp.fluid}
                alt={slide.content.altText}
              />
            </TabPanel>
          ))}
        </div>

        <TabList className={styles.tabContainer}>
          <Tab
            data-tab="slideA"
            onClick={handleSlideState}
            className={`${styles.tab} ${isSlidesActive.slideA &&
              styles.active}`}
          >
            <div className={styles.titleContainer}>
              <span className={styles.titleIcon}>
                {/* prettier-ignore */}
                <svg height="28" width="28" xmlns="http://www.w3.org/2000/svg"><path d="M3.52 13.977a1.497 1.497 0 100 2.994 7.485 7.485 0 017.486 7.485 1.497 1.497 0 002.994 0c0-5.787-4.692-10.48-10.48-10.48zM3.52 2a1.497 1.497 0 000 2.994c10.749 0 19.463 8.714 19.463 19.462a1.497 1.497 0 102.994 0C25.977 12.054 15.923 2 3.52 2zm0 5.988a1.497 1.497 0 100 2.995c7.442 0 13.474 6.032 13.474 13.473a1.497 1.497 0 002.994 0c0-9.095-7.373-16.468-16.468-16.468z" fill="#6e5ec3"/></svg>
              </span>
              <h3 className={styles.title}>{props.slides[0].tab.title}</h3>
            </div>
            <p className={styles.subtext}>{props.slides[0].tab.subtext}</p>
            {isMobile && (
              <Img
                fluid={props.slides[0].content.imageFile.childImageSharp.fluid}
                alt={props.slides[0].content.altText}
              />
            )}
          </Tab>

          <Tab
            data-tab="slideB"
            onClick={handleSlideState}
            className={`${styles.tab} ${isSlidesActive.slideB &&
              styles.active}`}
          >
            <div className={styles.titleContainer}>
              <span className={styles.titleIcon}>
                {/* prettier-ignore */}
                <svg height="28" width="28" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M2.3 1.52h23.4c.718 0 1.3.559 1.3 1.248v22.464c0 .69-.582 1.248-1.3 1.248H2.3c-.718 0-1.3-.559-1.3-1.248V2.768c0-.69.582-1.248 1.3-1.248zm9.1 22.464h13v-8.736h-13zm0-11.232h13V4.016h-13zM3.6 23.984h5.2V4.016H3.6z" fill="#6e5ec3" fillRule="nonzero"/><path d="M17.714 14.464V24.68" stroke="#6e5ec3" strokeLinecap="square" strokeWidth="2.7"/></g></svg>
              </span>
              <h3 className={styles.title}>{props.slides[1].tab.title}</h3>
            </div>
            <p className={styles.subtext}>{props.slides[1].tab.subtext}</p>
            {isMobile && (
              <Img
                fluid={props.slides[1].content.imageFile.childImageSharp.fluid}
                alt={props.slides[1].content.altText}
              />
            )}
          </Tab>

          <Tab
            data-tab="slideC"
            onClick={handleSlideState}
            className={`${styles.tab} ${isSlidesActive.slideC &&
              styles.active}`}
          >
            <div className={styles.titleContainer}>
              <span className={styles.titleIcon}>
                {/* prettier-ignore */}
                <svg height="28" width="28" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="#6e5ec3" strokeWidth="2.5" transform="translate(0 .5)"><circle cx="14" cy="13.462" r="12.212"/><path d="M14.249 1.73c-3.147 2.993-5.095 7.142-5.095 11.727 0 4.482 1.861 8.547 4.884 11.523" strokeLinecap="round"/> <path d="M13.751 1.577c3.147 2.993 5.095 7.142 5.095 11.728 0 4.482-1.861 8.547-4.884 11.523"/><path d="M25.846 13.462H2.154" strokeLinecap="square"/></g></svg>
              </span>
              <h3 className={styles.title}>{props.slides[2].tab.title}</h3>
            </div>
            <p className={styles.subtext}>{props.slides[2].tab.subtext}</p>
            {isMobile && (
              <Img
                fluid={props.slides[2].content.imageFile.childImageSharp.fluid}
                alt={props.slides[2].content.altText}
              />
            )}
          </Tab>

          <Tab
            data-tab="slideD"
            onClick={handleSlideState}
            className={`${styles.tab} ${isSlidesActive.slideD &&
              styles.active}`}
          >
            <div className={styles.titleContainer}>
              <span className={styles.titleIcon}>
                {/* prettier-ignore */}
                <svg height="28" width="28" xmlns="http://www.w3.org/2000/svg"><path d="M14.14 8.97a4.104 4.104 0 014.728.707 3.998 3.998 0 010 5.656l-3.779 3.842a1.336 1.336 0 01-1.896 0L9.413 15.4a3.998 3.998 0 010-5.656 4.104 4.104 0 014.728-.774zm-2.83 4.482l2.83 2.828 2.832-2.828a1.333 1.333 0 000-1.894 1.337 1.337 0 00-1.883 0 1.336 1.336 0 01-1.896 0 1.337 1.337 0 00-1.883 1.894zM14.14.5c7.376 0 13.356 5.972 13.356 13.339a13.183 13.183 0 01-3.019 8.444l2.671 2.667c.38.384.49.958.28 1.454-.226.491-.727.797-1.268.774H14.14C6.766 27.178.787 21.206.787 13.839S6.766.5 14.14.5zm0 24.01h8.802L21.7 23.27a1.333 1.333 0 010-1.881 10.662 10.662 0 001.336-13.473 10.691 10.691 0 00-12.969-3.939C5.357 5.922 2.678 10.92 3.67 15.913s5.375 8.593 10.472 8.597z" fill="#6e5ec3"/></svg>
              </span>
              <h3 className={styles.title}>{props.slides[3].tab.title}</h3>
            </div>
            <p className={styles.subtext}>{props.slides[3].tab.subtext}</p>
            {isMobile && (
              <Img
                fluid={props.slides[3].content.imageFile.childImageSharp.fluid}
                alt={props.slides[3].content.altText}
              />
            )}
          </Tab>
        </TabList>
      </Tabs>
    </div>
  )
}

export default HomeNotebook
