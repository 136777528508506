import React from "react"
import { Link } from "gatsby"
import styles from "../../styles/features-grid-block.module.scss"

const FeaturesGridBlock = props => {
  const wpBorderTop = props.border.top
  const wpBorderBottom = props.border.bottom
  const wpPaddingTop = props.padding.top
  const wpPaddingBottom = props.padding.botton
  const wpBgColor = props.bgColor

  const wpSectionStyles = {
    backgroundColor: wpBgColor ? wpBgColor : null,
  }

  const wpContainerStyles = {
    borderTop: wpBorderTop ? "1px solid #E6E8EB" : null,
    borderBottom: wpBorderBottom ? "1px solid #E6E8EB" : null,
    paddingTop: wpPaddingTop ? `${wpPaddingTop}px` : null,
    paddingBottom: wpPaddingBottom ? `${wpPaddingBottom}px` : null,
  }

  return (
    <div className={styles.section} style={wpSectionStyles}>
      <div className={styles.container} style={wpContainerStyles}>
        <div className={styles.textContainer}>
          <div className={styles.eyebrow}>{props.eyebrow}</div>
          <div className={styles.title}>{props.title}</div>
          {props.subtext && (
            <div className={styles.subtext}>{props.subtext}</div>
          )}
        </div>
        <div className={styles.featuresContainer}>
          {props.features.map((feature, index) => {
            if (feature.link) {
              return (
                <Link
                  to={feature.link.url}
                  key={index}
                  className={styles.feature}
                >
                  <div className={styles.featureTextContainer}>
                    <div className={styles.featureTitle}>{feature.title}</div>
                    <div className={styles.featureSubtext}>
                      {feature.subtext}
                    </div>
                  </div>
                </Link>
              )
            } else {
              return (
                <div
                  key={index}
                  className={`${styles.feature} ${styles.noUrl}`}
                >
                  <div className={styles.featureTextContainer}>
                    <div className={styles.featureTitle}>{feature.title}</div>
                    <div className={styles.featureSubtext}>
                      {feature.subtext}
                    </div>
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default FeaturesGridBlock
