import React from "react"
import { useMediaQuery } from "react-responsive"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import Img from "gatsby-image"

import styles from "../../styles/mobile-notebook.module.scss"

import mobileCalendar from "../../images/mobile-calendar2.png"
import mobileConferencing from "../../images/mobile-conferencing2.png"
import mobileManager from "../../images/mobile-manager2.png"
import mobileScreenshare from "../../images/mobile-screenshare2.png"
import mobileVirtualBackground from "../../images/mobile-virtual-bg.png"

const MobileNotebook = props => {
  const isMobile = useMediaQuery({ maxWidth: 1000 })

  const wpTabColumnStyles = {
    maxWidth: props.tabColumnWidth ? `${props.tabColumnWidth}px` : null,
    marginRight: props.tabsMargin ? `${props.tabsMargin}px` : null,
  }

  const wpImageStyles = {
    maxWidth: props.imageSize.width ? `${props.imageSize.width}px` : null,
    maxHeight: props.imageSize.height ? `${props.imageSize.height}px` : null,
  }

  const wpContainerStyles = {
    paddingTop:
      props.padding.top && !isMobile ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom && !isMobile ? `${props.padding.bottom}px` : null,
  }

  return (
    <div
      className={styles.section}
      style={{ backgroundColor: props.background ? props.background : null }}
    >
      <div className={styles.container} style={wpContainerStyles}>
        <div
          className={styles.header}
          style={{
            maxWidth: props.titleWidth ? `${props.titleWidth}px` : null,
          }}
        >
          <div className={styles.eyebrow}>{props.eyebrow}</div>
          <h2 className={styles.copy}>{props.title}</h2>
        </div>

        <Tabs className={styles.notebookContainer}>
          <TabList
            className={styles.tabContainer}
            style={!isMobile ? wpTabColumnStyles : null}
          >
            {props.tabs.map((tab, index) => (
              <Tab key={index} className={styles.tab}>
                <div className={styles.tabTitle}>{tab.title}</div>
                <p className={styles.tabText}>{tab.subtext}</p>
              </Tab>
            ))}
          </TabList>

          <TabPanel>
            <img
              src={mobileConferencing}
              alt="mobile conferencing creative"
              style={wpImageStyles}
            />
          </TabPanel>

          <TabPanel>
            <img
              src={mobileScreenshare}
              alt="mobile screen share creative"
              style={wpImageStyles}
            />
          </TabPanel>

          <TabPanel>
            <img
              src={mobileManager}
              alt="mobile manager creative"
              style={wpImageStyles}
            />
          </TabPanel>

          <TabPanel>
            <img
              src={mobileCalendar}
              alt="mobile calendar creative"
              style={wpImageStyles}
            />
          </TabPanel>

          <TabPanel>
            <img
              src={mobileVirtualBackground}
              alt="mobile virtual background creative"
              style={wpImageStyles}
            />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

export default MobileNotebook
