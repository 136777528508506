import React from "react"
import styles from "../../styles/hero-light-split.module.scss"

import mobileCreative from "../../images/mobile-meetings-creative.png"
import appleBadge from "../../images/apple-app-store-badge.svg"

const HeroLightSplit = props => {
  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <h1 className={styles.eyebrow}>{props.eyebrow}</h1>
          <h2 className={styles.copy}>{props.copy}</h2>
          <div
            className={styles.subtext}
            dangerouslySetInnerHTML={{ __html: props.subtext }}
          />
          <div className={styles.badgeContainer}>
            <a
              href="https://apps.apple.com/us/app/vast-conference/id1457035935"
              target="_blank"
              className={styles.appleBadge}
            >
              <img src={"images/apple-badge.png"} alt="apple badge" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.vast.conference"
              target="_blank"
              className={styles.googlePlayBadge}
            >
              <img
                src={"images/google-play-badge.png"}
                alt="google play badge"
              />
            </a>
          </div>
        </div>
        <div className={styles.imageContainer}>
        </div>
        <img
          className={styles.heroImage}
          src={mobileCreative}
          alt="mobile-creative"
        />
      </div>
    </div>
  )
}

export default HeroLightSplit
