import React, { useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import Img from "gatsby-image"
import styles from "../../styles/downloads-blocks.module.scss"

const DownloadsBlocks = props => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  let isAndroid
  let isWindows

  if (typeof window !== `undefined`) {
    const ua = window.navigator.userAgent.toLowerCase()
    isAndroid = ua.indexOf("android") > -1
    isWindows = window.navigator.platform.indexOf("Win") > -1
  }

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        {props.groups.map((group, index) => (
          <div
            key={index}
            className={`${styles.groupBlock} ${
              group.downloadItems.length < 2 ? styles.singleItem : ""
            }`}
          >
            <div className={styles.header}>
              <h2>{group.groupTitle}</h2>
              <p>{group.groupSubtext}</p>
            </div>

            {group.downloadItems.map((item, index) => (
              <div key={index} className={styles.downloadItem}>
                <div className={styles.content}>
                  {item.icon && (
                    <div className={styles.iconContainer}>
                      <Img
                        fixed={item.icon.imageFile.childImageSharp.fixed}
                        alt={item.icon.altText}
                      />
                    </div>
                  )}

                  <div className={styles.textContainer}>
                    <h2>{item.title}</h2>
                    {item.subtext && <p>{item.subtext}</p>}
                  </div>
                </div>
                {item.macLink && (
                  <div className={styles.linkContainer}>
                    {item.title === "Vast Conference" && (
                      <>
                        <a
                          href={item.macLink.url}
                          className={styles.downloadButton}
                          style={{
                            marginBottom: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "17px 20px",
                          }}
                        >
                          Mac
                          {/* prettier-ignore */}
                          <svg height="15" width="13" xmlns="http://www.w3.org/2000/svg"><path d="M12.045 5.864H8.773V.954h-4.91v4.91H.592l5.727 5.727zM.591 13.227v1.637h11.454v-1.637z" fill="#fff"/></svg>
                        </a>
                        <a
                          href={item.windowsLink.url}
                          className={styles.downloadButton}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "17px 20px",
                          }}
                        >
                          Windows
                          {/* prettier-ignore */}
                          <svg height="15" width="13" xmlns="http://www.w3.org/2000/svg"><path d="M12.045 5.864H8.773V.954h-4.91v4.91H.592l5.727 5.727zM.591 13.227v1.637h11.454v-1.637z" fill="#fff"/></svg>
                        </a>
                      </>
                    )}

                    {item.title !== "Vast Conference" && (
                      <a
                        href={item.macLink.url}
                        className={styles.downloadButton}
                      >
                        {item.macLink.title}
                        {/* prettier-ignore */}
                        <svg height="15" width="13" xmlns="http://www.w3.org/2000/svg"><path d="M12.045 5.864H8.773V.954h-4.91v4.91H.592l5.727 5.727zM.591 13.227v1.637h11.454v-1.637z" fill="#fff"/></svg>
                      </a>
                    )}
                  </div>
                )}

                {item.downloadButton === "App Store Badge" && (
                  <div className={styles.badges}>
                    {!isMobile && (
                      <>
                        <a
                          href="https://apps.apple.com/us/app/vast-conference/id1457035935"
                          target="_blank"
                        >
                          <img
                            src={"/images/apple-badge.png"}
                            alt="apple store"
                          />
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.vast.conference"
                          target="_blank"
                        >
                          <img
                            src={"/images/google-play-badge.png"}
                            alt="google play"
                          />
                        </a>
                      </>
                    )}

                    {isMobile && !isAndroid && (
                      <a
                        href="https://apps.apple.com/us/app/vast-conference/id1457035935"
                        target="_blank"
                      >
                        <img
                          src={"/images/apple-badge.png"}
                          alt="apple store"
                        />
                      </a>
                    )}

                    {isMobile && isAndroid && (
                      <a
                        href="https://play.google.com/store/apps/details?id=com.vast.conference"
                        target="_blank"
                      >
                        <img
                          src={"/images/google-play-badge.png"}
                          alt="google play"
                        />
                      </a>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default DownloadsBlocks
