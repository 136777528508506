import React from "react"
import { useMediaQuery } from "react-responsive"
import camelcase from "lodash.camelcase"
import styles from "../../styles/features-box-threecol.module.scss"
import { checkPropTypes } from "prop-types"

const FeaturesBoxThreeCol = props => {
  const isMobile = useMediaQuery({ maxWidth: 1100 })

  const wordpressStyles = {
    backgroundColor: props.bgColor,
    paddingTop: props.padding.top ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom && !isMobile ? `${props.padding.bottom}px` : null,
  }

  return (
    <div className={styles.section} style={wordpressStyles}>
      <div
        className={`${styles.container} ${
          props.class ? styles[props.class] : ""
        }`}
      >
        {(props.eyebrow || props.title || props.subtext) && (
          <div className={styles.textContainer}>
            {props.eyebrow && (
              <div className={styles.eyebrow}>{props.eyebrow}</div>
            )}
            {props.title && <h2 className={styles.title}>{props.title}</h2>}
            {props.subtext && <p className={styles.subtext}>{props.subtext}</p>}
          </div>
        )}
        <div className={styles.featuresContainer}>
          {props.features.map((feature, index) => (
            <div key={index} className={styles.feature}>
              <div className={styles.iconContainer}>
                <img
                  src={`/images/acf-icons/${feature.icon.slug}.svg`}
                  alt={feature.icon.altText}
                />
              </div>
              <div className={styles.featureTextContainer}>
                <div className={styles.featureTitle}>{feature.title}</div>
                <div
                  className={styles.featureSubtext}
                  dangerouslySetInnerHTML={{ __html: feature.subtext }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FeaturesBoxThreeCol
