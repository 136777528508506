import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { useMediaQuery } from "react-responsive"
import Slider from "react-slick"
import Img from "gatsby-image"

import styles from "../../styles/home-testimonials.module.scss"
import fiveStars from "../../images/five-stars.jpg"

const HomeTestimonials = props => {
  const isSmall = useMediaQuery({ maxWidth: 1105 })
  const isMobile = useMediaQuery({ maxWidth: 768 })

  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplaySpeed: 15000,
    speed: 800,
    slidesToShow: isSmall ? (isMobile ? 1 : 2) : isMobile ? 1 : 3,
    slidesToScroll: isSmall ? (isMobile ? 1 : 2) : isMobile ? 1 : 3,
    dotsClass: "reactz-dots",
  }

  const starsImage = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "five-stars.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.icon}>
            <img
              width="22"
              height="20"
              src={"/images/heart-icon.png"}
              alt="heart icon"
              loading="eager"
            />
          </div>
          <h2 className={styles.title}>{props.header.title}</h2>
          <p className={styles.subtext}>{props.header.subtext}</p>
        </div>
        <div className={styles.sliderContainer}>
          <Slider className={styles.slider} {...settings}>
            {props.testimonials.map((testimonial, index) => (
              <div key={index} className={styles.slide}>
                <div className={styles.quote}>
                  <Img
                    className={styles.stars}
                    fluid={starsImage.file.childImageSharp.fluid}
                    alt="five stars"
                  />
                  {`"${testimonial.quote}"`}
                </div>
                <div className={styles.nameCompany}>
                  <div className={styles.logo}>
                    <Img
                      fluid={testimonial.logo.imageFile.childImageSharp.fluid}
                      alt={testimonial.logo.altText}
                    />
                  </div>
                  <div className={styles.name}>{testimonial.name}</div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default HomeTestimonials
