import React, { useState } from "react"
import { useMediaQuery } from "react-responsive"
import { Link } from "gatsby"
import Modal from "react-modal"
import { Formik, Form, Field, ErrorMessage, withFormik } from "formik"
import * as yup from "yup"
import BackgroundImage from "gatsby-background-image"
import styles from "../../styles/hero-image-block.module.scss"
import formStyles from "../../styles/form-styles.module.scss"
import closeIcon from "../../images/close-icon.svg"

const HeroImageBlock = props => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const [isModalOpen, setModalOpen] = useState(false)

  const handleSetModalOpen = () => setModalOpen(!isModalOpen)

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      width: "550px",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      boxShadow: "0px 7px 80px rgba(0, 0, 0, 0.1)",
      border: "none",
      padding: "40px 30px",
      paddingTop: "50px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, .6)",
      zIndex: 9999,
    },
  }

  const customModalStylesMobile = {
    content: {
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      width: "100%",
      borderRadius: "0",
      padding: "40px 30px",
      paddingTop: "50px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, .6)",
      zIndex: 9999,
    },
  }

  return (
    <BackgroundImage
      className={styles.hero}
      fluid={props.bgImage.imageFile.childImageSharp.fluid}
      backgroundColor={`#7661d1`}
    >
      <div>
        <div
          className={`${styles.container} ${
            props.textAlign === "left" ? styles.textLeft : ""
          }`}
        >
          <div
            className={`${styles.textContainer} ${
              props.class ? styles[props.class] : ""
            }`}
          >
            {props.eyebrow && (
              <div className={styles.eyebrow}>{props.eyebrow}</div>
            )}
            {props.title && (
              <>
                <h1 className={styles.title}>{props.title}</h1>
                <h2 className={styles.copy}>{props.copy}</h2>
              </>
            )}

            {!props.title && <h1 className={styles.copy}>{props.copy}</h1>}
            <div
              className={styles.subtext}
              dangerouslySetInnerHTML={{ __html: props.subtext }}
            />
            <div className={styles.buttonContainer}>
              {props.button && (
                <Link to={props.button.url} className={styles.button}>
                  {props.button.title}
                </Link>
              )}
              {props.secondaryButton &&
                props.secondaryButton.title.toLowerCase().includes("login") && (
                  <button
                    className={styles.secondaryButton}
                    onClick={handleSetModalOpen}
                  >
                    {props.secondaryButton.title}
                  </button>
                )}

              {props.secondaryButton &&
                !props.secondaryButton.title
                  .toLowerCase()
                  .includes("login") && (
                  <Link
                    to={props.secondaryButton.url}
                    className={styles.secondaryButton}
                  >
                    {props.secondaryButton.title}
                  </Link>
                )}
            </div>
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          style={isMobile ? customModalStylesMobile : customModalStyles}
          onRequestClose={handleSetModalOpen}
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
        >
          <div className={styles.formContainer}>
            <button
              onClick={handleSetModalOpen}
              className={styles.modalCloseButton}
            >
              <img src={closeIcon} alt="close icon" />
            </button>
            <LoginForm />
          </div>
        </Modal>
      </div>
    </BackgroundImage>
  )
}

let loginValidation = yup.object().shape({
  email: yup
    .string()
    .min(8, "Email must have 8 characters")
    .max(255, "Email must not exceed 255 characters")
    .required("Enter an email address")
    .email("Must be a valid email address"),
  password: yup
    .string()
    .trim()
    .required("Must enter a password"),
})

const LoginForm = () => {
  const submitServicePost = (
    values,
    { setSubmitting, resetForm, setErrors }
  ) => {
    setSubmitting(true)

    setTimeout(() => {
      resetForm()
      setSubmitting(false)
    }, 1000)
  }

  const Form = withFormik({
    handleSubmit: submitServicePost,
    validationSchema: loginValidation,
    mapPropsToValues: props => ({
      email: "",
      password: "",
    }),
  })(props => {
    const {
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
      touched,
      errors,
      values,
    } = props

    return (
      <form
        action={process.env.GATSBY_SALESFORCE_CASE_URL}
        method="POST"
        onSubmit={handleSubmit}
        // ref={caseFormEl}
      >
        <div className={styles.formTitle}>Login to Your Affiliate Account</div>
        <div className={styles.nameContainer}>
          <label className={formStyles.label} htmlFor="email">
            <div className={formStyles.required}>Email</div>
          </label>
          <input
            className={`${
              touched.email && errors.email ? formStyles.error : null
            } ${formStyles.input}`}
            id="email"
            name="email"
            type="text"
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
            maxLength="80"
            placeholder="Your Email Address"
            // ref={inputRef}
          />
          <ErrorMessage name="email">
            {msg => <div className={formStyles.errorTip}>{msg}</div>}
          </ErrorMessage>
        </div>

        <div className={styles.nameContainer}>
          <label className={formStyles.label} htmlFor="password">
            <div className={formStyles.required}>Password</div>
          </label>
          <input
            className={`${
              touched.password && errors.password ? formStyles.error : null
            } ${formStyles.input}`}
            id="password"
            name="password"
            type="text"
            value={values.password}
            onBlur={handleBlur}
            onChange={handleChange}
            maxLength="80"
            placeholder="Your Password"
            type="password"
            // ref={inputRef}
          />
          <ErrorMessage name="password">
            {msg => <div className={formStyles.errorTip}>{msg}</div>}
          </ErrorMessage>
        </div>

        <div className={styles.submitContainer}>
          <button
            disabled={isSubmitting}
            type="submit"
            style={{ opacity: isSubmitting ? ".5" : "1" }}
          >
            Log In
          </button>
        </div>
      </form>
    )
  })

  return <Form />
}

export default HeroImageBlock
