import React from "react"
import { useMediaQuery } from "react-responsive"
import Img from "gatsby-image"
import styles from "../../styles/features-image-left.module.scss"

const FeaturesImageLeft = props => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const wordpressStyles = {
    backgroundColor: props.bgColor,
    paddingTop:
      props.padding.top && !isMobile ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom && !isMobile ? `${props.padding.bottom}px` : null,
  }

  return (
    <div
      className={`${styles.section} ${props.class ? styles[props.class] : ""}`}
      style={wordpressStyles}
    >
      <div className={styles.container}>
        <div className={styles.textContainer}>
          {props.title && <h2 className={styles.title}>{props.title}</h2>}
          {props.subtext && (
            <div
              className={styles.subtext}
              dangerouslySetInnerHTML={{ __html: props.subtext }}
            />
          )}
        </div>
        <div className={styles.imageFeaturesContainer}>
          <div className={styles.imageContainer}>
            <div className={styles.imageWrap}>
              <Img
                fluid={props.image.imageFile.childImageSharp.fluid}
                alt={props.image.altText}
              />
            </div>
          </div>
          <div className={styles.featuresContainer}>
            {props.features.map((feature, index) => (
              <div key={index} className={styles.feature}>
                <h3 className={styles.title}>{feature.title}</h3>
                <div
                  className={styles.subtext}
                  dangerouslySetInnerHTML={{ __html: feature.subtext }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturesImageLeft
