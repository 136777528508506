import React from "react"
import { useMediaQuery } from "react-responsive"
import camelcase from "lodash.camelcase"
import styles from "../../styles/features-box-list.module.scss"

const FeaturesBoxList = props => {
  const isMobile = useMediaQuery({ maxWidth: 1100 })

  const wordpressStyles = {
    backgroundColor: props.bgColor,
    paddingTop: props.padding.top ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom && !isMobile ? `${props.padding.bottom}px` : null,
  }

  return (
    <div
      className={`${styles.section} ${props.class ? styles[props.class] : ""}`}
      style={wordpressStyles}
    >
      <div className={styles.container}>
        {(props.eyebrow || props.title || props.subtext) && (
          <div className={styles.textContainer}>
            {props.eyebrow && (
              <div className={styles.eyebrow}>{props.eyebrow}</div>
            )}
            <h2 className={styles.title}>{props.title}</h2>
            <p className={styles.subtext}>{props.subtext}</p>
          </div>
        )}
        <div className={styles.featuresContainer}>
          {props.features.map((feature, index) => (
            <div key={index} className={styles.feature}>
              {feature.icon && (
                <div className={styles.iconContainer}>
                  <img
                    src={`/images/acf-icons/${feature.icon.slug}.svg`}
                    alt={feature.icon.altText}
                  />
                </div>
              )}
              <div className={styles.featureTextContainer}>
                <div className={styles.featureTitle}>{feature.title}</div>
                <div
                  className={styles.featureSubtext}
                  dangerouslySetInnerHTML={{ __html: feature.subtext }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FeaturesBoxList
