import React from "react"
import { useMediaQuery } from "react-responsive"
import styles from "../../styles/hero-color-block.module.scss"

const HeroColorBlock = props => {
  const isMobile = useMediaQuery({ maxWidth: 1100 })

  const wordpressStyles = {
    backgroundColor: props.bgColor,
    paddingTop: props.paddingTop ? `${props.paddingTop}px` : null,
    paddingBottom:
      props.paddingBottom && !isMobile ? `${props.paddingBottom}px` : null,
  }

  return (
    <div className={styles.hero} style={wordpressStyles}>
      <div
        className={`${styles.container} ${
          props.class ? styles[props.class] : ""
        }`}
      >
        <div className={styles.textContainer}>
          {props.title && (
            <>
              <h1 className={styles.title}>{props.title}</h1>
              <h2 className={styles.copy}>{props.copy}</h2>
            </>
          )}

          {!props.title && <h1 className={styles.copy}>{props.copy}</h1>}
          <div
            className={styles.subtext}
            dangerouslySetInnerHTML={{ __html: props.subtext }}
          />
        </div>
      </div>
    </div>
  )
}

export default HeroColorBlock
