import React from "react"
import { useMediaQuery } from "react-responsive"
import Img from "gatsby-image"

import styles from "../../styles/social-proof.module.scss"

const SocialProof = props => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const wordpressStyles = {
    backgroundColor: props.bgColor,
  }

  const wpContainerStyles = {
    paddingTop:
      props.padding.top && !isMobile ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom && !isMobile ? `${props.padding.bottom}px` : null,
    borderTop: props.border.top ? "1px solid #e5e7ee" : null,
    borderBottom: props.border.bottom ? "1px solid #e5e7ee" : null,
  }

  return (
    <div className={styles.section} style={wordpressStyles}>
      <div className={styles.container} style={wpContainerStyles}>
        <div
          className={`${styles.badgeContainer} ${
            props.class ? styles[props.class] : ""
          }`}
        >
          {props.badges.map((badge, index) => (
            <div key={index} className={styles.imageContainer}>
              <Img
                fluid={badge.badge.imageFile.childImageSharp.fluid}
                alt={badge.badge.altText}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SocialProof
