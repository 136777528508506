import React from "react"
import { useMediaQuery } from "react-responsive"

import styles from "../../styles/pricing-box-twocol-highlight.module.scss"

const PricingBoxTwoColHighlight = props => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const wordpressStyles = {
    backgroundColor: props.bgColor,
    paddingTop:
      props.padding.top && !isMobile ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom && !isMobile ? `${props.padding.bottom}px` : null,
  }

  return (
    <div
      className={`${styles.section}`}
      style={wordpressStyles}
    >
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <h2 className={styles.copy}>{props.copy}</h2>
          <div className={styles.subtext}>{props.subtext}</div>
        </div>

        <div className={styles.pricingContainer}>
          <div className={styles.pricingBoxes}>
            {props.pricingBoxes.map((box, index) => (
              <div key={index} className={styles.box}>
                <div className={styles.planType}>{box.planType}</div>
                <div className={styles.price}>{box.price}</div>
                <div className={styles.priceSub}>{box.priceSubtext}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricingBoxTwoColHighlight
