import React from "react"
import { useMediaQuery } from "react-responsive"
import styles from "../../styles/features-twocol.module.scss"
import camelcase from "lodash.camelcase"

const FeaturesTwoCol = props => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const wordpressStyles = {
    backgroundColor: props.bgColor,
    paddingTop:
      props.padding.top && !isMobile ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom && !isMobile ? `${props.padding.bottom}px` : null,
  }

  return (
    <div
      className={`${styles.section} ${props.class ? styles[props.class] : ""}`}
      style={wordpressStyles}
    >
      <div className={styles.container}>
        <div className={styles.textContainer}>
          {props.eyebrow && (
            <div className={styles.eyebrow}>{props.eyebrow}</div>
          )}
          <h2 className={styles.title}>{props.title}</h2>
          {props.subtext && <p styles={styles.subtext}>{props.subtext}</p>}
        </div>
        <div className={styles.featuresContainer}>
          {props.features.map((feature, index) => (
            <div key={index} className={styles.feature}>
              <div className={styles.iconContainer}>
                <img
                  src={`/images/acf-icons/${feature.icon.slug}.svg`}
                  alt={feature.icon.altText}
                />
              </div>
              <div className={styles.featureTextContainer}>
                {feature.title && (
                  <div className={styles.featureTitle}>{feature.title}</div>
                )}
                <div className={styles.featureSubtext}>{feature.subtext}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FeaturesTwoCol
