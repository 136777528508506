import React from "react"
import { useMediaQuery } from "react-responsive"
import Img from "gatsby-image"
import styles from "../../styles/features-image-threecol.module.scss"

const FeaturesImageThreeCol = props => {
  const isMobile = useMediaQuery({ maxWidth: 1100 })

  const wordpressStyles = {
    paddingTop: props.padding.top ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom && !isMobile ? `${props.padding.bottom}px` : null,
  }

  return (
    <div className={styles.container} style={wordpressStyles}>

      <div className={styles.textContainer}>
        <h2 className={styles.headerTitle}>{props.title}</h2>
        <p styles={styles.headerSubtext}>{props.subtext}</p>
      </div>

      <div className={styles.featuresContainer}>
        {props.features.map((feature, index) => (
          <div key={index} className={styles.feature}>
            <div className={styles.iconContainer}>
              <Img
                fluid={feature.image.imageFile.childImageSharp.fluid}
                alt={feature.image.altText}
              />
            </div>
            <div className={styles.featureTextContainer}>
              <div className={styles.featureTitle}>{feature.title}</div>
              <div className={styles.featureSubtext}>{feature.subtext}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FeaturesImageThreeCol
