import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { useMediaQuery } from "react-responsive"
import Img from "gatsby-image"

import styles from "../../styles/home-cta.module.scss"

const HomeCTA = props => {
  const isMobile = useMediaQuery({ maxWidth: 560 })

  const data = useStaticQuery(graphql`
    query {
      leftCta: file(relativePath: { eq: "cta-left-bg.jpg" }) {
        childImageSharp {
          fixed(quality: 85, width: 315) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      rightCta: file(relativePath: { eq: "cta-right-bg.jpg" }) {
        childImageSharp {
          fixed(quality: 85, width: 315) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      mobileCta: file(relativePath: { eq: "home-mobile-cta.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 560) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className={styles.section}>
      {!isMobile && (
        <span className={styles.leftImage}>
          <Img
            fixed={data.leftCta.childImageSharp.fixed}
            alt="left cta background"
          />
        </span>
      )}
      <div className={styles.container}>
        {isMobile && (
          <div className={styles.mobileCtaContainer}>
            <Img
              className={styles.mobileCta}
              fluid={data.mobileCta.childImageSharp.fluid}
              alt="mobile cta background"
            />
          </div>
        )}
        <div
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: props.copy }}
        />
        <p className={styles.subtext}>{props.subtext}</p>
        <Link to={props.button.url}>{props.button.title.toUpperCase()}</Link>
      </div>
      {!isMobile && (
        <span className={styles.rightImage}>
          <Img
            fixed={data.rightCta.childImageSharp.fixed}
            alt="right cta background"
          />
        </span>
      )}
    </div>
  )
}

export default HomeCTA
