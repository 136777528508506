import React from "react"
import { Link } from "gatsby"
import { useMediaQuery } from "react-responsive"

import styles from "../../styles/text-button-banner.module.scss"

const TextButtonBanner = props => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const wordpressStyles = {
    backgroundColor: props.bgColor,
    paddingTop:
      props.padding.top && !isMobile ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom && !isMobile ? `${props.padding.bottom}px` : null,
  }

  return (
    <div
      className={`${styles.section} ${props.class ? styles[props.class] : ""}`}
      style={wordpressStyles}
    >
      <div className={styles.container}>
        <h2 className={styles.copy}>{props.copy}</h2>
        <div className={styles.buttonContainer}>
          <Link to="/contact" className={styles.button}>
            {props.button.title}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default TextButtonBanner
