import React from "react"
import { useMediaQuery } from "react-responsive"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styles from "../../styles/hero-highlight.module.scss"

const HeroHighlight = props => {
  const isMobile = useMediaQuery({ maxWidth: 1040 })

  const wpPaddingStyles = {
    paddingTop: props.padding.top !== null ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom !== null ? `${props.padding.bottom}px` : null,
  }

  return (
    <div
      className={`${styles.section} ${props.class ? styles[props.class] : ""}`}
      style={{
        backgroundColor: `${props.background ? props.background : null}`,
      }}
    >
      <div
        className={`${styles.container}`}
        style={!isMobile ? wpPaddingStyles : null}
      >
        <div className={`${styles.textContainer}`}>
          <div className={styles.eyebrow}>{props.eyebrow}</div>
          <h1 className={styles.title}>{props.title}</h1>
          <div
            className={styles.subtext}
            dangerouslySetInnerHTML={{ __html: props.subtext }}
          />
          {props.button && (
            <Link to={props.button.url} className={styles.button}>
              {props.button.title}
            </Link>
          )}
        </div>
        <div className={styles.imageSpacer}></div>
        <div className={styles.imageContainer}>
          <Img
            fluid={props.image.imageFile.childImageSharp.fluid}
            alt={props.image.altText}
          />
        </div>
      </div>
    </div>
  )
}

export default HeroHighlight
