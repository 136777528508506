import React from "react"
import { useMediaQuery } from "react-responsive"
import Img from "gatsby-image"
import styles from "../../styles/features-image-twocol.module.scss"

const FeaturesImageTwoCol = props => {
  const isMobile = useMediaQuery({ maxWidth: 1100 })

  const wordpressStyles = {
    paddingTop: props.padding.top ? `${props.padding.top}px` : null,
    paddingBottom:
      props.padding.bottom && !isMobile ? `${props.padding.bottom}px` : null,
  }
  return (
    <div className={styles.container} style={wordpressStyles}>
      {props.title || props.subtext ? (
        <div className={styles.textContainer}>
          {props.eyebrow && (
            <div className={styles.eyebrow}>{props.eyebrow}</div>
          )}
          {props.title && <h2 className={styles.title}>{props.title}</h2>}
          {props.subtext && <p className={styles.subtext}>{props.subtext}</p>}
        </div>
      ) : null}
      <div className={styles.featuresContainer}>
        {props.features.map((feature, index) => (
          <div key={index} className={styles.feature}>
            <div className={styles.iconContainer}>
              <Img
                fluid={feature.image.imageFile.childImageSharp.fluid}
                alt={feature.image.altText}
              />
            </div>
            <div className={styles.featureTextContainer}>
              <div className={styles.featureTitle}>{feature.title}</div>
              <div
                className={styles.featureSubtext}
                dangerouslySetInnerHTML={{ __html: feature.subtext }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FeaturesImageTwoCol
