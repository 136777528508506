import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styles from "../../styles/hero-sub-nav.module.scss"

const HeroSubNav = props => {
  const [scrollTop, setScrollTop] = useState(0)
  const winPath = typeof window !== "undefined" ? window.location.pathname : ""

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(
        e.target.documentElement.scrollTop || e.target.body.scrollTop
      )
    }
    window.addEventListener("scroll", onScroll)

    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollTop])

  return (
    <div className={`${styles.subNav} ${scrollTop > 5 && styles.scrolled}`}>
      <ul>
        {props.nav.map((navItem, index) => (
          <li
            key={index}
            className={
              navItem.navItem.url.includes(winPath) ? styles.active : ""
            }
          >
            <Link to={navItem.navItem.url}>{navItem.navItem.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default HeroSubNav
