import React from "react"
import { useMediaQuery } from "react-responsive"
import { Link } from "gatsby"

import styles from "../../styles/pricing-box-twocol.module.scss"

const PricingBoxTwoCol = props => {
  const isMobile = useMediaQuery({ maxWidth: 1100 })

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.pricingContainer}>
          {props.pricingBoxes.map((pricingBox, index) => (
            <div key={index} className={styles.pricingBox}>
              <div className={styles.textContainer}>
                <div className={styles.planType}>{pricingBox.planType}</div>
                <div className={styles.price}>{pricingBox.price}</div>
                <div className={styles.priceSub}>{pricingBox.priceSubtext}</div>

                <div
                  className={styles.subtext}
                  dangerouslySetInnerHTML={{ __html: pricingBox.subtext }}
                />
              </div>

              <Link className={styles.planButton} to={pricingBox.button.url}>
                {pricingBox.button.title}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PricingBoxTwoCol
