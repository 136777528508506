import React from "react"
import styles from "../../styles/features-list-twocol.module.scss"
import checkmark from "../../images/checkmark-icon.svg"

const FeaturesListTwoCol = props => {
  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.featuresContainer}>
          {props.lists.map((list, index) => (
            <div key={index} className={styles.list}>
              <h2>{list.list.title}</h2>
              {list.list.features.map((feature, index) => {
                if (feature.title !== "none") {
                  return (
                    <div key={index} className={styles.feature}>
                      <div className={styles.titleContainer}>
                        <span className={styles.icon}>
                          <img src={checkmark} alt="checkmark" />
                        </span>
                        <span className={styles.title}>{feature.title}</span>
                      </div>
                      <div
                        className={styles.subtext}
                        dangerouslySetInnerHTML={{ __html: feature.subtext }}
                      />
                    </div>
                  )
                } else {
                  return (
                    <div key={index} className={styles.infoBox}>
                      <div
                        className={styles.subtext}
                        dangerouslySetInnerHTML={{ __html: feature.subtext }}
                      />
                    </div>
                  )
                }
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FeaturesListTwoCol
