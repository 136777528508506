import React from "react"
import { useMediaQuery } from "react-responsive"
import { Link } from "gatsby"

import styles from "../../styles/hero-text-block.module.scss"

const HeroTextBlock = props => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const wordpressStyles = {
    paddingTop: props.paddingTop && !isMobile ? `${props.paddingTop}px` : null,
    paddingBottom:
      props.paddingBottom && !isMobile ? `${props.paddingBottom}px` : null,
  }

  return (
    <div
      className={`${styles.hero} ${props.class ? styles[props.class] : ""}`}
      style={wordpressStyles}
    >
      <div className={styles.container}>
        <div className={styles.textContainer}>
          {props.title && <h1 className={styles.title}>{props.title}</h1>}
          {props.title ? (
            <h2 className={styles.copy}>{props.copy}</h2>
          ) : (
            <h1 className={styles.copy}>{props.copy}</h1>
          )}
          <div
            className={styles.subtext}
            dangerouslySetInnerHTML={{ __html: props.subtext }}
          />
          {props.button && (
            <Link to={props.button.url} className={styles.ctaButton}>
              {props.button.title}
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default HeroTextBlock
